.invitation-management {
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .store-controls {
      display: flex;
      gap: 1rem;
      align-items: center;

      .store-select {
        min-width: 200px;
      }
    }
  }

  .search-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    input {
      max-width: 300px;
    }
  }

  .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;

    &.pending {
      background-color: #fff3cd;
      color: #856404;
    }

    &.complete {
      background-color: #d4edda;
      color: #155724;
    }
  }

  .form-group {
    margin-bottom: 1rem;

    .error-message {
      color: #dc3545;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }

    .form-control.error {
      border-color: #dc3545;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }

  .products-section {
    .products-list {
      max-height: 200px;
      overflow-y: auto;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin: 1rem 0;

      .product-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #dee2e6;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .selected-products {
      margin-top: 1rem;

      .selected-product-item {
        display: grid;
        grid-template-columns: 1fr 120px 80px;
        gap: 1rem;
        align-items: center;
        margin-bottom: 0.5rem;
      }
    }
  }

  .pagination-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
