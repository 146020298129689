::-webkit-scrollbar {
  width: 4px;
  height: 0px;
  opacity: 0.1;
}

::-webkit-scrollbar-button {
  background: #ffffff;
}

::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #2f7efe;
  border-radius: 2px;
}

.topfilter a.show-sub[aria-expanded="true"] {
  transform: rotate(180deg);
}

.topfilter .dropdown-menu {
  overflow: visible;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 0.2rem 1rem;
  position: relative;
}

.topfilter .dropdown-menu li {
  position: relative;
}

.topfilter .dropdown-menu li ul.submenu {
  display: none;
  padding: 0.5rem 0;
  position: absolute;
  left: 100%;
  top: 0;
  background: #fff;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  border-radius: 0.55rem;
}

.topfilter .dropdown-menu li:hover ul.submenu {
  display: inline-block;
}

.topfilter .dropdown-toggle {
  position: relative;
  pointer-events: inherit;
}

.topfilter .dropdown-toggle span.cl-fl {
  position: absolute;
  right: 14px;
  color: #ff8484;
}

.variants-tooltip {
  max-width: 400px;
}

.variants-tooltip .variant-box {
  max-height: 200px;
  overflow: auto;
}

.dropdown-menu .submenu .dropdown-item {
  position: relative;
  padding: 0.2rem 2rem 0.2rem 1rem;
}

.dropdown-menu .submenu-arrow > .dropdown-item::after {
  content: "\f054";
  font-family: FontAwesome;
  border: 0;
  vertical-align: middle;
  margin-left: 0.25em;
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 9px;
}

.product-rating-menu .dropdown-menu {
  width: 300px;
}

.rc-slider-rail {
  background-color: #e9e9e9;
}

.rc-slider-track,
.rc-slider-tracks {
  background-color: #79acff;
}

.rc-slider-handle {
  border: solid 2px #2f7efe;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #2f7efe;
  box-shadow: 0 0 0 5px #2f7efe;
}

.rc-slider-handle:focus-visible {
  border-color: #2db7f5;
  box-shadow: 0 0 0 3px #2f7efe;
}

.rc-slider-handle-click-focused:focus {
  border-color: #2f7efe;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #2f7efe;
}

.rc-slider-handle:active {
  border-color: #2f7efe !important;
  box-shadow: 0 0 5px #2f7efe !important;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.prd-img {
  height: 70px;
  overflow: hidden;
  border-radius: 13px;
  flex: 0 0 auto;
  width: 70px;
  position: relative;
  background-color: #000;
}

.prd-img .btn {
  border: 0;
}

.prd-img:hover {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.prd-img:hover img {
  opacity: 0.7;
}

.prd-img .video-popup-btn {
  position: absolute;
  top: 18px;
  left: 26px;
  z-index: 998;
  background-color: transparent;
  border: 0;
  font-size: 22px;
  color: #2f7efe;
  display: none;
}

.prd-img:hover .video-popup-btn {
  display: inline-block;
}

.prd-img img {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.prd-img-dtl {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 12px;
}

.prd-img-front {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 13px;
  flex: 0 0 auto;
  position: relative;
}

.prd-img-front img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.prd-img-front .video-popup-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: transparent;
  border: 0;
  font-size: 60px;
  color: #ffffff;
  height: 100%;
  width: 100%;
}

.prd-img-front .video-popup-btn:focus,
.prd-img-front .video-popup-btn:not(:disabled):not(.disabled):active {
  background-color: transparent;
}

.prd-img-dtl img {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.prd-title {
  max-width: 190px;
  padding-right: 20px;
  flex: 0 0 auto;
  width: 190px;
}

.prd-title p {
  line-height: 1;
  word-break: break-word;
}

.prd-title-dtl p {
  line-height: 1;
}

.prd-title-list {
  max-width: 450px;
  padding-right: 20px;
  flex: 0 0 auto;
  width: 450px;
}

.prd-title-list p {
  line-height: 1;
  word-break: break-word;
}

.prd-title-list-dtl p {
  line-height: 1;
}

.product-slider img {
  height: 56px;
  width: 56px;
  object-fit: cover;
  margin-right: 5px;
  border-radius: 5px;
}

.product-slider .carousel-caption {
  position: relative;
  top: auto;
  left: auto;
  right: 0;
  text-shadow: none;
  text-align: left;
  width: 200px;
}

.product-slider .carousel-caption p {
  color: #000;
  line-height: 1;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.product-slider .carousel-inner {
  padding: 0 20px;
  height: 57px;
}

.product-slider .carousel-control-prev,
.product-slider .carousel-control-next {
  width: auto;
}

.product-slider .carousel-control-prev .carousel-control-prev-icon {
  background-image: none;
  position: relative;
}

.product-slider .carousel-control-prev .carousel-control-prev-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f053";
  width: 10px;
  height: 10px;
  font-family: "FontAwesome";
  color: #000;
}

.product-slider .carousel-control-next .carousel-control-next-icon {
  background-image: none;
  position: relative;
}

.product-slider .carousel-control-next .carousel-control-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f054";
  width: 10px;
  height: 10px;
  color: #000;
  font-family: "FontAwesome";
}

.product-slider .carousel-indicators {
  display: none;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  z-index: 99875;
}

.loader-wrapper-2 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: grid;
  place-items: center;
  z-index: 99875;
}

.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.slick-slider.table-sl-slider {
  margin: 0 20px;
  width: 261px;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  opacity: 1;
  color: #c7c7c7;
  right: 0;
  top: 0;
}

.video-popup .modal-content {
  background-color: transparent;
  border: 0;
}

.video-popup .modal-header {
  border: 0;
}

.video-popup .btn {
  padding: 5px 12px;
  border: 0;
  border-radius: 20px;
}

.p-variants .v-truck {
  flex: 0 0 auto;
  width: 30px;
}

.fh-table {
  max-height: 600px;
  overflow: auto;
}

.blur-filter {
  filter: blur(5px);
  cursor: no-drop;
}

.fc-daygrid-event-harness {
  text-align: center;
  display: inline-block;
}

.fc-event {
  display: inline-block;
  font-size: 14px;
  height: 25px;
  width: 25px;
  border-radius: 1.125rem;
  font-weight: 600;
  line-height: 1;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc-view {
  margin-top: 0.875rem;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  text-align: center;
}

.fc-header-toolbar .fc-toolbar-chunk:last-child {
  display: none;
}

.cal-date {
  text-align: right !important;
  position: absolute;
  right: 15px;
  z-index: 998;
  bottom: -42px;
}

.react-datepicker-popper {
  z-index: 998;
  width: 170px;
}

.react-datepicker__navigation-icon {
  top: 6px;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 2.9rem;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.line-through {
  text-decoration: line-through;
}

.loader-warp {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-search .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.header-search .btn {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.brand-trust {
  background-color: #e1ecff;
}

.brand-trust-cta .brand-trust-wrap {
  background-color: #e1ecff;
  border-radius: 20px;
}

.slider-signup {
  background-color: #2f7efe;
  height: 100%;
  border-radius: 20px;
}

.slider-signup .slider-signup-wrap {
  position: absolute;
  color: #ffffff;
  top: 0;
  padding: 40px;
  width: 100%;
  height: 100%;
}

.pricing-table .single-table .content-creation-img {
  height: 310px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 7px;
}

.pricing-table .single-table .content-creation-img img {
  width: 100%;
  object-fit: cover;
  height: 310px;
  object-position: top;
}

.influencersuser-img {
  width: 100%;
  object-fit: cover;
  height: 310px;
  object-position: top;
  border-radius: 7px;
  margin-top: 30px;
}

.modal-amazon-products {
  max-width: 1700px;
}

.card-box .card-head {
  background-color: #427bfd;
}

.card-box .card {
  max-width: 326px;
}

.card-box .card-head-2 {
  background-color: #3b6ad4;
}

.card-box .card-head-3 {
  background-color: #345ebf;
}

.card-box .card-head-4 {
  background-color: #2c52a8;
}

.card-box .card-head-5 {
  background-color: #254284;
}

.profit-datepicker .dropdown-menua.date-active {
  min-width: 70rem;
  width: 100%;
}

.profit-datepicker .dropdown-item {
  padding: 3px 0;
  font-size: 13px;
}

.multi-select-dropdown {
  position: relative;
  width: 100%;
}

#search-box {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.multi-select-dropdown .dropdown-content {
  display: block;
}

.dropdown-content label {
  display: block;
  padding: 5px 8px;
  cursor: pointer;
  font-size: 13px;
}

.dropdown-content label:hover {
  background-color: #f1f1f1;
}

.dropdown-content input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.selected-options {
  position: absolute;
  top: 4px;
  right: 0;
}

.selected-options span {
  display: inline-block;
  background-color: #e0e0e0;
  padding: 5px 40px 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.selected-options .btn {
  position: absolute;
  z-index: 998;
  right: 0;
  border: 0;
  color: #2c52a8;
  font-weight: bold;
  top: 5px;
}

.selected-options .btn:hover {
  background-color: transparent;
  /* color: #FF4C41; */
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.search-wrap {
  position: relative;
}

/* .dt-hvr:hover .dropdown-menu {
    display: block;
} */
.custom-tab-1 .nav-tabs .nav-item {
  margin-bottom: 1px;
}

.dropdowna {
  position: relative;
  display: inline-block;
}

.dropdown-togglea {
  background-color: #fff;
  color: #007bff;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #007bff;
}

.dropdown-menua {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
}

.dropdown-menua ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menua li {
  padding: 10px;
  cursor: pointer;
}

/* .dropdown-menua li:hover {
    background-color: #f1f1f1;
} */

.dropdown-menua a {
  text-decoration: none;
  color: black;
}

.dropdown-menua a:hover {
  text-decoration: underline;
}

.custom-tab-1 .nav-link.bg-primary:focus,
.custom-tab-1 .nav-link.bg-primary:hover,
.custom-tab-1 .nav-link.bg-primary.active {
  background-color: #0c5fe3 !important;
  border-color: #034bbf !important;
}

.dashboard-number {
  color: #000;
  font-size: 24px;
  font-weight: 500 !important;
}

.dashboard-h-text {
  color: #00000099;
  font-weight: 400;
  font-size: 15px !important;
}

.dashboard-hr {
  color: #00000099;
  font-weight: 400;
  font-size: 15px !important;
  margin: 8px !important;
}
