/* ManageOrdersPage.css */
.manage-orders-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5;
}

.order-details {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
}

.order-number {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
.order-status.order-title,
.package-info .item-price,
.package-info .item-name,
.customer-paid-details .total .label,
.customer-paid-details .total .value,
.customer-paid-label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.package-info .item-price,
.package-info .item-name{
    font-size: 14px;
    margin-bottom: 0px;
}
.package-info .sku,
.package-info .dflt{
    font-size: 14px;
}
.order-info .label{
    padding: .2rem 0;
}

.order-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.order-info .label {
    font-weight: bold;
}
.order-info .value{
    font-size: 13px;
}
.order-info .label{
    font-size: 14px;
}


.package-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: 1px solid #d5d5d5;
    padding: 15px;
    border-radius: 10px;
}
.package-info .item-price{
   min-width: 110px;
}
.item-details{
    display: flex;
}
.payment-method{
    display: flex;
    justify-content: space-between;
}
.customer-paid-details .accordion-item:first-of-type .accordion-button{
    border: none;
    background-color: transparent;
    color: #7e7e7e;
    padding: 0 0 10px 0;
    justify-content: end;
}
.customer-paid-details .accordion-button::after{
    margin-left: 5px;
    transform: rotate(180deg);
}

.customer-paid-details .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
}
.customer-paid-details .accordion-body{
    padding: 0px;
}
.customer-paid-details .accordion-item:last-of-type .accordion-collapse{
    border: none;
}

.item-image img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
}

.customer-details {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
}

.customer-paid-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}
.payment-method{
    font-display: flex;
    justify-content: space-between;
}

.customer-paid-details .label {
    font-weight: bold;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.refund-button,
.more-actions-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.refund-button {
    background-color: #e53935;
    color: white;
}

.more-actions-button {
    background-color: #4caf50;
    color: white;
}

.order-subtotal {
    font-size: 14px;
}